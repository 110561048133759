import React from 'react';
import {EmailRounded, Facebook, Instagram, PhoneRounded} from "@mui/icons-material";
import Slide1 from "../img/pic2-cropped2.jpg";
import ImageTitleCard from "./ImageTitleCard";
import Icon1 from "../img/icon/9.png";
import ATV from "../img/icon/ATV2.png";
import Buggy from "../img/icon/Buggy3.png";
import ImageTitleDescriptionCard from "./ImageTitleDescriptionCard";
import Camping from "../img/camping.jpg";
import Traseu1 from "../img/traseu-2.jpg";
import ATV1 from "../img/atv-1.jpg";
import ATV2 from "../img/atv-2.jpg";
import SXS from "../img/sxs.jpg";
import {Box} from "@mui/material";
import Reservations, {MainProps} from "./Reservations";
import Footer from "./Footer";
import ATVImageTitleDescriptionCard from "./ATVImageTitleCard";
import {Language} from "../App";

const Main = ({reservations, setLanguage, language}: MainProps) => {
    return (
        <div className="App">
            <div className="wrapper">
                <header id="header" className="header-area absolute-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <div className="header-top-contact">
                                    <ul>
                                        <li>
                                            <a href="mailto:craciunel_iosif@yahoo.com">
                                                <EmailRounded/>
                                                craciunel_iosif@yahoo.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:0726133032">
                                                <PhoneRounded/>
                                                +40 726 133 032
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=61568747931685"
                                               target="_blank" rel="noopener noreferrer">
                                                <Facebook style={{marginRight: '8px'}}/>
                                                Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/wild_trip_pestenita?igsh=d2pkYmlzdGk0Nzls"
                                               target="_blank" rel="noopener noreferrer">
                                                <Instagram style={{marginRight: '8px'}}/>
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => setLanguage(Language.english)}>
                                                <span style={{color: 'white'}}>RO</span>
                                                <span style={{color: 'lightgrey'}}> / EN</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="slider-area">
                    <div id="welcome-slide" className="slide-controls">
                        <div className="single-slide">
                            <div className="slide-thumb shape-1" style={{overflow: 'hidden'}}>
                                <img className={'main-image'} src={Slide1} alt=""/>
                                <div className="slide-content">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 col-sm-12">
                                                <div className="slide-content-area">
                                                    <div className="single-slide-content text-center">
                                                        <div className="content-text">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="info-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* Romanian Government Emblem */}
                            <div className="col-md-2 col-sm-3 text-center">
                                <img src="/romania.png" alt="Stema Guvernului României" className="banner-icon"/>
                            </div>

                            {/* Central Text */}
                            <div className="col-md-8 col-sm-6 text-center">
                                <h2 className="banner-title">Programul Național de Dezvoltare Rurală</h2>
                                <p className="banner-subtitle">Program finanțat de Uniunea Europeană și Guvernul
                                    României</p>
                                <p className="banner-subtitle">Fondul European Agricol pentru Dezvoltare Rurală</p>
                                <p className="banner-note">„Europa investește în zonele rurale”</p>

                                {/* Information from the image */}
                                <div className="project-details">
                                    <h3>Diversificarea Activității Crăciunel Ciprian-Iosif</h3>
                                    <p><strong>Beneficiar:</strong> Crăciunel Ciprian-Iosif Întreprindere Individuală
                                    </p>
                                    <p><strong>Valoarea totală eligibilă a proiectului:</strong> 50,000 Euro</p>
                                    <p><strong>Finanțare publică prin PNDR:</strong> 50,000 Euro</p>
                                    <p><strong>Perioada de implementare:</strong> 06/05/2022 - 06/02/2025</p>
                                </div>
                            </div>

                            {/* EU Logo */}
                            <div className="col-md-2 col-sm-3 text-center">
                                <img src="/europe.png" alt="Uniunea Europeană" className="banner-icon"/>
                            </div>
                        </div>

                        {/* AFIR Link */}
                        <div className="row">
                            <div className="col text-center mt-3">
                                <a
                                    href="https://www.afir.ro/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-link"
                                >
                                    Vizitează site-ul AFIR
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="quick-icon-area pb-90">
                    <div className="container">
                        <div className="row">
                            <ImageTitleCard img={Icon1} title={'Camping'}/>
                            <ImageTitleCard img={ATV} title={'ATV'}/>
                            <ImageTitleCard img={Buggy} title={'SSV'}/>
                        </div>
                    </div>
                </div>
                <div className="about-area pb-115">
                    <div className="about-bg">
                        <video
                            className={'video'}
                            controls
                            autoPlay
                            muted
                            loop
                            playsInline
                            style={{
                                borderRadius: 20,
                                height: 'auto',
                                width: '100%'
                            }}
                        >
                            <source src={'videos/about.mp4'} type={'video/mp4'}/>
                        </video>
                    </div>
                    <div className="container" style={{maxWidth: '100%'}}>
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-lg-6">
                                <div className="section-title" style={{marginBottom: 15}}>
                                    <h2 style={{marginBottom: 15}}>ÎNCEPE-ȚI aventura<span></span> cu <b>Wild Trip</b>
                                    </h2>
                                </div>
                                <div className="about-content">
                                    <p style={{marginBottom: 10}}>Pentru că iubim aventura, ne place să descoperim
                                        trasee
                                        inedite și suntem dependenți de adrenalina oferită de o plimbare offroad, am
                                        decis să oferim acest gen de distracție și altor iubitori de aventură.</p>
                                    <p style={{marginBottom: 10}}> Zona noastră, situată la poalele munților Retezat,
                                        oferă peisaje și trasee spectaculoase atât pentru cei care vor să iasă din
                                        cotidian, cât și pentru cei care caută adrenalină. Tot ce trebuie să faci este
                                        să iei un ATV și să pornești in Aventură!</p>
                                    <p style={{marginBottom: 10}}>
                                        Ghidul nostru îți va arăta cum să folosești corect ATV-ul și îți va prezenta
                                        normele de siguranță, iar în funcție de complexitatea traseului ales, te va
                                        însoți pe toată durata plimbării.
                                    </p>
                                    <p style={{marginBottom: 10, fontWeight: 500}}>
                                        Bucură-te de natură și in același timp ai parte de relaxare, distracție,
                                        aventură și adrenalină!
                                    </p>
                                    <a href="#reservations" className="btn btn-theme">Rezervă acum!</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="adventure-area pb-90 pt-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-md-8">
                                <div className="section-title text-center">
                                    <h2 className="title">Ce îți oferim</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ImageTitleDescriptionCard img={Camping} title={'Camping'} description={
                                <>
                                    <p>Punem la dispoziție terenul situat în apropierea locației noastre pentru campare
                                        rulote. Parcele sunt delimitate pentru până la 5 rulote. Posibilitate de
                                        racordare la curent electric, apă. Acces la toalete, mașină de
                                        spălat, internet.</p>
                                    <p> Obiective turistice apropiate : Biserica Sfântul Ierarh Nicolae din Densuș, Tăul
                                        fără fund, Muzeul etnografic Peșteana, Biserica Pogorărea Sfântului Duh
                                        Peșteana, Casa Vulcanilor, Mănăstirea Prislop, Valea Râului Mare, Geoparcul
                                        Dinozaurilor ”Țara Hațegului”, Munții Retezat etc…</p>
                                    <p>Prețul parcelei de parcare este de 100 RON/ 24h.</p>
                                    <p>Ne bucurăm să vă avem oaspeți cât mai mult pentru a gusta din plin farmecul
                                        vieții la țară.</p>
                                </>
                            }/>
                            <ImageTitleDescriptionCard img={Traseu1} title={'Trasee ATV/SSV'} description={
                                <>
                                    <p>Plimbarea cu ATV-urile se face pe bază de buletin, permis de conducere categoria
                                        B și semnarea unui contract de Plimbare.</p>
                                    <p>La final ne vom asigura împreuna că ne-ai adus ATV-ul în regulă, pentru a se
                                        putea bucura de el și alți aventurieri.</p>
                                    <p>Plimbare ATV CFORCE – 150 RON/h</p>
                                    <p>Plimbare SSV ZFORCE – 150 RON/h</p>
                                    <p>Plimbarea se va efectua pe drumul comunal 90 și pe drumul național 68.</p>
                                    <p>Traseul va începe din satul Peștenița, va urma DC 90 spre și prin satul
                                        Peșteana, până la intersecția cu DN 68, apoi se va continua pe DN 68 până în
                                        Comuna Sarmizegetusa cu punct terminus la Cetatea Ulpia Traiana Sarmizegetusa și
                                        Muzeul de Arheologie Sarmizegetusa și retur pe același traseu spre locul de
                                        plecare.</p>
                                    <p>Traseu de 9,9 km, care poate fi parcurs, dus-intors în circa 2 ore.</p>
                                </>
                            }/>
                        </div>
                    </div>
                </div>
                <div className="funfact-area overlay-bg pt-170 pb-70">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-8">
                                <div className="section-title white text-center">
                                    <h2 className="title">ATV-urile noastre</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ATVImageTitleDescriptionCard
                                img={ATV1}
                                title={'CFORCE625 TOURING - 40CP'}
                            />
                            <ATVImageTitleDescriptionCard
                                img={ATV2}
                                title={'CFORCE 850XC - 59CP'}
                            />

                            <ATVImageTitleDescriptionCard
                                img={SXS}
                                title={'ZFORCE 1000Sport - 78CP'}
                            />
                        </div>
                    </div>
                </div>

                <Box id={'reservations'} className="partner-area pb-220" paddingTop={'50px'}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center">
                                    <h2 className="title">Rezervă acum</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                            </div>
                        </div>
                    </div>
                    <Reservations reservations={reservations} language={language}/>
                </Box>

                <Footer/>
            </div>
            <script src="js/vendor/jquery-3.3.1.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/owlcarousel.min.js"></script>
            <script src="js/meanmenu.min.js"></script>
            <script src="js/counterup.min.js"></script>
            <script src="js/scrollup.min.js"></script>
            <script src="js/waypoints.min.js"></script>
            <script src="js/imagesloaded.pkgd.min.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/main.js"></script>
        </div>
    );
}

export default Main;