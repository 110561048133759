import React from 'react';

const Terms = () => {
    return (
        <>
            <h2 style={{textAlign: 'center'}}><b>TERMENE ȘI CONDIȚII</b></h2>
            <div style={{padding: 48}}>
                <p>Website-ul <a href="https://wildtrip.ro">https://wildtrip.ro</a> poate fi
                    utilizat în acord cu termenii și condițiile de mai jos.</p><p>Crăciunel
                Ciprian Iosif Întreprindere Individuala îşi rezervă dreptul de a aduce oricând modificări în conţinutul
                acestui site, precum şi a
                Termenilor şi Condiţiilor de utilizare, fără să fie necesară notificarea în prealabil a
                Utilizatorilor/Clienţilor.</p><p><strong>Drepturi de autor</strong></p><p>
            </p><p>Conținutul site-ului www.wildtrip.ro care constă în imagini,
                texte, elemente de grafică, video, software – aparține în totalitate societățiimai sus numite și
                este protejat conform Legii nr. 8 / 14.03.1996 privind dreptul de autor şi drepturile conexe şi de
                legile române şi europene privind proprietatea intelectuală şi industrială. </p>
                <p>Folosirea fără acordul Crăciunel Ciprian Iosif Întreprindere Individuala a oricăror elemente
                    enumerate mai sus, cu excepție a
                    imaginilor gratuite preluate din platformele menționate anterior, se pedepseşte conform legislaţiei
                    în vigoare.</p><p>Societatea Crăciunel Ciprian Iosif Întreprindere Individuala poate
                oferi Utilizatorului /
                Clientului, printr-un acord, dreptul de a utiliza parțial conținutul acestui website, sub forma
                descrisă. Acest acord se aplică strict pentru conţinutul definit, pe o perioadă stabilită în acord şi
                doar pentru persoana/societatea căreia i s-a permis utilizarea acestui conţinut, fără a putea utiliza şi
                alte conținuturi ale site-ului wildtrip.ro.</p><p><strong>Colectarea și
                prelucrarea datelor personale</strong></p><p>Prin „date cu caracter
                personal” se înțelege orice informație privind o persoană fizică identificată și, respectiv, o persoană
                juridică: nume, prenume, adresă de e-mail, telefon, datele de identificare ale persoanei fizice,
                respectiv juridice (CNP/CUI/CIF), datele contului bancar, date generale despre website-ul şi businessul
                Utilizatorului/Clientului.</p><p>Modalitățile de colectare a datelor personale utilizate de
                website-ul <a href="https://wildtrip.ro/">https://wildtrip.ro</a> sunt: prin
                intermediul formularelor de rezervare.</p><p>Scopul colectării acestor date cu caracter
                personal constă în gestiunea rezervării spatiilor de camping, cat si a plimbarii cu ATV-urile.</p>
                <p>Societatea Crăciunel Ciprian Iosif Întreprindere Individuala nu va face publice şi nu va vinde sau
                    distribui bazele de date ce
                    conţin informaţii referitoare la datele personale ale Utilizatorilor/Clienţilor săi.</p>
                <p>Conform Legii nr. 677/2001 pentru protecţia persoanelor cu privire la prelucrarea
                    datelor cu caracter personal şi libera circulaţie a acestor date, modificată şi completată, şi Legii
                    nr.
                    506/2004 privind prelucrarea datelor cu caracter personal şi protecţia vieţii private în sectorul
                    comunicaţiilor electronice, societatea are obligaţia de a administra în condiţii de siguranţă şi
                    numai
                    pentru scopurile specificate datele personale furnizate de clienţi, un membru al familiei acestora
                    ori o
                    altă persoană.</p><p>Societatea garantează Clienților săi drepturile prevăzute de Legea nr.
                677/2001 pentru protecţia persoanelor cu privire la prelucrarea datelor cu caracter personal şi libera
                circulaţie a acestora, respectiv dreptul de informare, dreptul de acces la date, dreptul de intervenţie,
                dreptul de opoziţie, dreptul de a nu fi supus unei decizii individuale, dreptul de a se adresa justiţiei
                în caz de încălcare a drepturilor sale. Clienţii au în permanență dreptul de a solicita ştergerea totală
                sau parţială a datelor cu caracter personal deținute de Craciunel Ciprian Iosif Întreprindere
                Individuala și de platforma
                www.wildtrip.ro. Clienții au posibilitatea de a solicita oricare dintre drepturile enumerate
                mai sus în baza unei cereri scrise, semnate şi datate, înaintată cătreBusiness
                GeneratorSRL.</p><p>Platforma www.wildtrip.ro nu va solicita sub nicio formă
                Utilizatorilor săi informații confidențiale, date despre conturi bancare, parole personale etc. În cazul
                în care Utilizatorul le divulgă unor terţi, acesta poartă responsabilitatea integrală pentru acţiunile
                sale. Astfel, în cazuri de divulgare a acestor date sau a unor date confidențiale, Utilizatorul nu poate
                trage la răspundere Crăciunel Ciprian Iosif Întreprindere Individuala pentru prejudiciile aduse.</p>
                <p>Website-ul nu reţine şi nu stochează sub nicio formă datele de card ale clienţilor. Acestea vor fi
                    accesate doar de către instituția de autorizare a Tranzacţiei sau o altă entitate autorizată să
                    presteze servicii de stocare date de identificare a cardului, despre a cărei entitate
                    Clientul/Utilizatorul va fi informat, anterior introducerii datelor.</p>
                <p><strong>Rezervări</strong></p>
                <p>Rezervările pot fi facute prin intermediul site-ului sau prin intermediul agentiei noastre. Toate
                    rezervarile sunt supuse disponibilitatii si selectarii clientilor si se confirma telefonic de catre
                    reprezentantii nostri.</p>
                <p><strong> Utilizarea ATV-ului</strong></p>
                <p>ATV-ul trebuie sa fie utilizat in conformitate cu instructiunile furnizate de catre agentia
                    noastra si cu legislatia in vigoare. Utilizatorul va fi responsabil pentru orice daune cauzate
                    ATV-ului.</p>
                <p>Aria geografică în care propunem să se desfășoare activitatea de agrement (plimbări cu ATV-ul) nu
                    este în arii naturale protejate sau/și în zone cu destinații eco.</p>
                <p>
                    <strong>Preturile</strong></p><p>Prețurile de pe website
                sunt stabilite în lei, conțin TVA, respectiv sunt prețuri brute.</p>
                <p><strong>Deșeuri reciclabile</strong></p>

                <p>Se vor depozita următoarele categorii de materiale reciclabile:</p>
                <ul>
                    <li>Hârtie, tipărituri – se vor depozita împachetate și legate</li>
                    <li>Carton – cutii de carton și ambalaje din carton – se depozitează pliate și legate</li>
                    <li>Metal – doze de băuturi, cutii de aluminiu, capace aluminiu – se depoziteaza strivite</li>
                    <li>Material plastic</li>
                    <ul>
                        <li>folie – pungi, sacoșe, etc.</li>
                        <li>flacoane: detergenți, săpun lichid etc.</li>
                        <li>pet-uri: ambalaje sucuri, apă etc. – strivite și fără dop</li>
                    </ul>
                </ul>

                <p><strong></strong></p>


                <p>SE INTERZICE depozitarea pungilor și ambalajelor de hârtie, carton și polietilenă ce conțin materiale
                    organice degradabile (sânge, carne, lactate, etc.)</p>

                <p><strong>Politica de confidențialitate</strong></p><p>Pentru utilizarea site-ului Wild Trip,
                https://wildtrip.ro, este necesar să citești și să accepți aceste reguli în totalitate.
                Vizitarea în continuare a acestui site presupune că ești de acord cu aceste reguli.</p>
                <p>Wild Trip poate să dețină informații despre tine, informații pe care ni le-ai oferit prin
                    rezervare. Aceste informații includ, numele
                    tău și numărul de telefon.</p><p><strong>În ce
                fel poate utiliza Wild Trip aceste informaţii personale?</strong></p><p>
            </p><p>Informațiile despre tine pot fi folosite de noi doar în scopuri asociate comercializării
                serviciilor
                și produselor Wild Trip, cum ar fi:</p>
                <ol>
                    <li>procesarea comenzilor sau cererilor tale;</li>
                    <li>oferirea de produse și/sau servicii către tine;</li>
                    <li>facturare;</li>
                    <li>soluționarea cererilor, întrebărilor sau a reclamațiilor făcute de către tine;</li>
                    <li>realizarea studiilor de piață și de produs, precum și marketingul produselor și al
                        serviciilor
                        Wild Trip;
                    </li>
                    <li>inregistrarea informațiilor despre tine în vederea alocării sau oferirii de recompense,
                        reduceri
                        și alte programe similare.
                    </li>
                </ol>
            </div>
        </>
    )
}

export default Terms;